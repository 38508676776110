import React from "react";
import {
 OverlayTrigger,
 Tooltip,
 Button,
 Popover,
 Row,
 Col,
} from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";
import dayjs from "dayjs";
import { capitalizeEachWord, getTimeAgo, stringIncludes } from "../utils/utils";

const TooltipIcon = ({ text, color = "rgba(52, 58, 64, 0.8)" }) => {
 const renderTooltip = (props) => <Tooltip id="tooltip">{props}</Tooltip>;

 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 0, hide: 200 }}
   overlay={renderTooltip(text)}
  >
   <span>
    <FaQuestionCircle size={15} color={color} />
   </span>
  </OverlayTrigger>
 );
};

const TooltipButton = ({
 text,
 color = "rgba(52, 58, 64, 0.8)",
 buttonText,
 buttonClassName,
 key,
 status,
 date,
 dateIn,
 dateResign,
 late = 0,
 lateInTolerance = 0,
 description = "",
 clockIn = null,
 clockOut = null,
 attendance = null,
 employee = null,
}) => {
 // Function to replace newline characters with <br />
 const formatText = (text, isTooltip = false) => {
  if (!text) return "";
  if (!description) {
   return text?.replace(/\n/g, "<br />");
  }
  if (!isTooltip) {
   return text + `\n...`.replace(/\n/g, "<br />");
  }
  return `${text}\n${description}`.replace(/\n/g, "<br />");
 };

 const renderTooltip = () => (
  //<Tooltip id="tooltip" style={{ maxWidth: "400px", position: "absolute" }}>
  // <span dangerouslySetInnerHTML={{ __html: formatText(props, true) }} />
  //</Tooltip>
  <Popover
   id="popover-basic popover"
   style={{ minWidth: "300px", maxWidth: "400px" }}
  >
   <Popover.Header as="h5">
    {attendance ? (
     <Row className="d-flex justify-content-between">
      <Col>
       <strong>{capitalizeEachWord(attendance?.status)}</strong>
      </Col>
      <Col className="text-end" md="auto">
       <small>{capitalizeEachWord(attendance?.name)}</small>
      </Col>
     </Row>
    ) : (
     <Row className="d-flex justify-content-between">
      <Col>
       <strong>Off / Tidak Hadir</strong>
      </Col>
      <Col className="text-end" md="auto">
       <small>{capitalizeEachWord(employee?.name)}</small>
      </Col>
     </Row>
    )}
   </Popover.Header>
   <Popover.Body>
    {attendance ? (
     <span
      dangerouslySetInnerHTML={{
       __html: formatText(
        `${
         attendance?.date
          ? dayjs(attendance?.date).format("dddd, DD-MMM-YYYY")
          : "-"
        }\n${text}`,
        true
       ),
      }}
     />
    ) : (
     <span
      dangerouslySetInnerHTML={{
       __html: formatText(
        `${date ? dayjs(date).format("dddd, DD-MMM-YYYY") : "-"}`,
        true
       ),
      }}
     />
    )}
   </Popover.Body>
   <div className="popover-footer">
    <Row className="d-flex justify-content-between">
     <Col className="d-flex align-items-center">
      <small
       className="text-muted"
       style={{ fontSize: "0.75em", marginLeft: "15px" }}
      >
       {attendance?.createdAt &&
        `Updated ${getTimeAgo(attendance?.updatedAt || attendance?.createdAt)}`}
      </small>
     </Col>
     <Col md="auto" className="d-flex justify-content-end align-items-center">
      <small
       className="text-muted"
       style={{ fontSize: "0.75em", marginRight: "15px" }}
      >
       {attendance?.addedByUserName &&
        `Edited by  ${attendance?.addedByUserName}`}
      </small>
     </Col>
    </Row>
   </div>
  </Popover>
 );

 //const [show, setShow] = useState(false);

 //useEffect(() => {
 // if (show) {
 //  const timer = setTimeout(() => setShow(false), 2000);
 //  return () => clearTimeout(timer);
 // }
 //}, [show]);
 const backgroundColor = (status, attendance) => {
  if (stringIncludes(attendance?.overtimeType, "longshift")) {
   return "rgb(100, 129, 100)";
  }
  if (stringIncludes(attendance?.status, "hanya lembur")) {
   return "rgba(127, 140, 141, 1)";
  }
  if (stringIncludes(attendance?.overtimeType, "hari libur nasional")) {
   return "rgb(251, 90, 90)";
  }
  if (attendance?.overtimeHours > 0) {
   return "rgba(204, 153, 255, 1)";
  }
  switch (status) {
   case "hadir":
    if (late > 0 && late <= lateInTolerance) {
     return "rgb(251, 217, 117)";
    } else if (late > lateInTolerance) {
     return "rgb(242, 153, 0)";
    }
    return "rgb(169, 199, 169)";
   case "sakit":
    return "rgb(251, 217, 117)";
   case "in class training":
    return "rgb(106, 213, 230)";
   default:
    return "rgb(242, 153, 153)";
  }
 };
 /* "select...",
    "hadir",
    "sakit",
    "sakit non surat",
    "izin",
    "absen",
    "cuti tahunan",
    "cuti istri melahirkan",
    "cuti melahirkan",
    "cuti anggota keluarga satu rumah meninggal",*/
 const icon = (status) => {
  switch (status) {
   case "in class training":
    return (
     <span
      dangerouslySetInnerHTML={{ __html: formatText(`${buttonText}\nclass`) }}
     />
    );
   case "hadir":
    return (
     <span dangerouslySetInnerHTML={{ __html: formatText(buttonText) }} />
    );
   case "hanya lembur":
    return (
     <span dangerouslySetInnerHTML={{ __html: formatText(buttonText) }} />
    );
   case "sakit":
    return `${
     clockIn && clockOut
      ? `${dayjs(clockIn).format("HH:mm")} - ${dayjs(clockOut).format(
         "HH:mm"
        )}\n`
      : ""
    }${description ? `sakit ....` : "sakit"}`;
   case "cuti tahunan":
    return description ? "cuti ...." : "cuti";
   case "cuti istri melahirkan":
    return description ? "cuti ...." : "cuti";
   case "cuti melahirkan":
    return description ? "cuti ...." : "cuti";
   case "cuti anggota keluarga satu rumah meninggal":
    return description ? "cuti ...." : "cuti";
   case undefined:
    if (!date || !dateIn || (dateResign && dateIn && date)) {
     if (dateResign) {
      const isAfterResigned =
       dayjs(date).isAfter(dateResign, "day") ||
       dayjs(date).isSame(dateResign, "day");
      if (isAfterResigned) {
       return (
        <i
         className="fas fa-sign-out-alt"
         style={{ color: "red", paddingRight: "2px" }}
        />
       );
      }
     }
     return <i className="fas fa-times" style={{ color: "red" }}></i>;
    }
    return date && dayjs(date).isBefore(dateIn, "day") ? (
     <i
      className="fas fa-sign-in-alt"
      style={{ color: "red", paddingRight: "2px" }}
     />
    ) : (
     <i className="fas fa-times" style={{ color: "red" }}></i>
    );
   default:
    return description ? `${status} ....` : status;
  }
 };
 return (
  <OverlayTrigger
   placement="top-start"
   delay={{ show: 200, hide: 200 }}
   overlay={renderTooltip()}
  >
   <button
    type="button"
    className={
     attendance?.status === "hanya lembur"
      ? "attendance-longshift-button"
      : attendance?.overtimeType === "hari libur nasional"
      ? "attendance-longshift-button"
      : attendance?.overtimeType === "longshift"
      ? "attendance-longshift-button"
      : attendance?.overtimeHours > 0 && attendance?.status !== "hanya lembur"
      ? "attendance-overtimehours-button"
      : status === "hadir"
      ? "attendance-hadir-button"
      : status === "in class training"
      ? "attendance-inclasstraining-button"
      : "attendance-tidakhadir-button"
    }
    style={{
     backgroundColor: backgroundColor(status, attendance),
     fontWeight:
      status !== "hadir" || attendance?.overtimeHours > 0 ? "500" : "0",
     fontSize: "9px",
     minHeight: "50px",
     maxHeight: "85px",
     minWidth: "28px",
     maxWidth: "30px",
     alignItems: "center",
    }}
   >
    {icon(status)}
   </button>
  </OverlayTrigger>
 );
};

function TooltipButtonSimple({
 text,
 variant = "light",
 buttonText,
 buttonBackgroundColor = null,
 buttonColor = "black",
 buttonFontSize = "8px",
 buttonMaxHeight,
}) {
 const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
   {text}
  </Tooltip>
 );

 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 250, hide: 400 }}
   overlay={text ? renderTooltip : <></>}
   rootClose // Agar popover menutup ketika klik di luar
   container={document.body} // Mengatur container untuk memastikan kalkulasi posisi benar
   boundary="window" // Cegah popover keluar dari layar
  >
   <Button
    size="sm"
    variant={buttonBackgroundColor ? "" : variant}
    className="d-flex align-items-center"
    style={{
     verticalAlign: "middle", // Vertical centering
     borderRadius: "5px",
     fontSize: buttonFontSize,
     color: buttonColor,
     backgroundColor: buttonBackgroundColor,
     height: "20px",
     maxHeight: buttonMaxHeight,
    }}
   >
    {buttonText}
   </Button>
  </OverlayTrigger>
 );
}

export { TooltipIcon, TooltipButton, TooltipButtonSimple };
