import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import dayjs from "dayjs";
import { stringIncludes } from "../../utils/utils";

export const Chart = ({
 chartType = "bar",
 title = "Title",
 allData = [],
 chartHeight = "45px",
 dataKey = ["attendanceSummary", "totalLateInDaysMoreThan5Min3x"], // Prop untuk jalur ke properti dalam objek
 backgroundColor = "rgba(255,255,255,.2)",
 borderColor = "rgba(255,255,255,.55)",
}) => {
 const { months, dataArray } = useMemo(() => {
  if (!allData) return undefined;
  const dataArray = allData?.map((record) => {
   // Menavigasi objek berdasarkan jalur yang diberikan
   return dataKey?.reduce((obj, key) => (obj ? obj[key] : undefined), record);
  });

  const months = allData?.map(
   (record) => record?.month && dayjs(record?.month).format("MMM")
  );

  return { months, dataArray };
 }, [allData, dataKey]);

 //console.log(
 // `months:${JSON.stringify(months, null, 2)}, dataArray:${JSON.stringify(
 //  dataArray,
 //  null,
 //  2
 // )}`
 //);

 //console.log(`testArray:${JSON.stringify(testArray, null, 2)}`);

 if (stringIncludes(chartType, "bar")) {
  return (
   <>
    <CChartBar
     className="mt-3 mx-3"
     style={{ height: chartHeight }}
     data={{
      labels: months,
      datasets: [
       {
        label: title,
        backgroundColor: "rgba(255,255,255,.2)",
        borderColor: "rgba(255,255,255,.55)",
        data: dataArray,
        barPercentage: 0.6,
       },
      ],
     }}
     options={{
      maintainAspectRatio: false,
      plugins: {
       legend: {
        display: false,
       },
      },
      scales: {
       x: {
        grid: {
         display: false,
         drawTicks: false,
        },
        ticks: {
         display: false,
        },
       },
       y: {
        border: {
         display: false,
        },
        grid: {
         display: false,
         drawBorder: false,
         drawTicks: false,
        },
        ticks: {
         display: false,
        },
       },
      },
     }}
    />
   </>
  );
 } else if (stringIncludes(chartType, "line")) {
  return (
   <CChartLine
    className="mt-3 mx-3"
    style={{ height: chartHeight }}
    data={{
     labels: months,
     datasets: [
      {
       label: title,
       backgroundColor: "transparent",
       borderColor: "rgba(255,255,255,.55)",
       pointBackgroundColor: "#5856d6",
       data: dataArray,
      },
     ],
    }}
    options={{
     plugins: {
      legend: {
       display: false,
      },
     },
     maintainAspectRatio: false,
     scales: {
      x: {
       border: {
        display: false,
       },
       grid: {
        display: false,
        drawBorder: false,
       },
       ticks: {
        display: false,
       },
      },
      y: {
       min: Math.min(...dataArray) - 6,
       max: Math.max(...dataArray) + 6,
       display: false,
       grid: {
        display: false,
       },
       ticks: {
        display: false,
       },
      },
     },
     elements: {
      line: {
       borderWidth: 1,
       tension: 0.4,
      },
      point: {
       radius: 0,
       hitRadius: 10,
       hoverRadius: 4,
      },
     },
    }}
   />
  );
 }
};

Chart.propTypes = {
 chartType: PropTypes.string,
 allData: PropTypes.array,
 title: PropTypes.string,
 chartHeight: PropTypes.string,
 dataKey: PropTypes.array,
 backgroundColor: PropTypes.string,
 borderColor: PropTypes.string,
};

export default Chart;
