import React, { useMemo } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";
import ReusableTable from "./ReusableTable";
import {
 getAttIdNameColumns,
 getColumn,
 getNumberColumn,
} from "../utils/utils";
import { getDateRange } from "../utils/time";

const AttendanceSummaryModalDetailsRekap = ({
 Title = "Title",
 visible = false,
 onClose = () => {},
 employees = [],
 startDate = null,
}) => {
 const columns = useMemo(
  () => [
   ...getNumberColumn(),
   ...getAttIdNameColumns(),
   getColumn("Outlet", "outlet", "left"),
   getColumn("Late In Minutes", "totalLateInMinutes", "right"),
   getColumn("Working Hours", "totalWorkingDurationInSeconds"),
   getColumn("Overtime Hours", "totalOvertimeHours", "right"),
   getColumn("Hadir", "totalHadir", "right"),
   getColumn("Absen", "totalAbsen", "right"),
   getColumn("Izin", "totalIzin", "right"),
   getColumn("Sakit", "totalSakit", "right"),
   getColumn("Sakit Non Surat", "totalSakitNonSurat", "right"),
   getColumn("Cuti Tahunan", "totalCutiTahunan", "right"),
   getColumn(
    "Attendance Ratio",
    "attendanceRatio",
    "right",
    false,
    false,
    null,
    true
   ),
  ],
  []
 );
 if (!employees?.length) return null;

 return (
  <Modal
   show={visible}
   onHide={onClose}
   size="xl"
   dialogClassName="modal-responsive"
   style={{ left: "20px" }}
   className="d-flex justify-content-center"
  >
   <Modal.Header closeButton>
    <Modal.Title>
     <Row>
      <Col>{Title}</Col>
     </Row>
     <Row>
      <Col style={{ fontSize: "0.75em" }}>
       {getDateRange(startDate, startDate)}
      </Col>
     </Row>
    </Modal.Title>
   </Modal.Header>
   <Modal.Body>
    {employees?.length > 0 ? (
     <ReusableTable columns={columns} data={employees} />
    ) : (
     <p className="text-center">No employee data available.</p>
    )}

    <Button variant="secondary" onClick={onClose}>
     Close
    </Button>
   </Modal.Body>
  </Modal>
 );
};

export default AttendanceSummaryModalDetailsRekap;
