import dayjs from "dayjs";

function getYearNow() {
 return dayjs().year();
}
function getMonthNow(date) {
 if (!date) return dayjs().month() + 1;
 return dayjs(date)?.month() + 1;
}

function getDateRange(startDate, endDate, longDate = true) {
 if (!startDate || !endDate) return "";
 const dateFormat = longDate ? "D MMMM YYYY" : "D-MMM-YYYY";
 const resultStartDate = dayjs(startDate).startOf("month").format(dateFormat);
 const resultEndDate = dayjs(endDate).endOf("month").format(dateFormat);
 if (!resultStartDate || !resultEndDate) return "";
 return `${resultStartDate} - ${resultEndDate}`;
}

export { getYearNow, getMonthNow, getDateRange };
