import React, { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import ReusableTable from "./ReusableTable";
import {
 getIdNameColumns,
 getColumn,
 getWorkDuration,
 getNumberColumn,
} from "../utils/utils";

const EmployeeSummaryModal = ({
 Title = "Title",
 visible = false,
 onClose = () => {},
 employees = [],
}) => {
 const columns = useMemo(
  () => [
   ...getNumberColumn(),
   ...getIdNameColumns(),
   getColumn("Outlet", "outlet", "left"),
   getColumn("Position", "position", "left"),
   getColumn("Status", "status"),
   getColumn("Grade", "grade", "left"),
   getColumn("Date Join", "dateIn", "right", false),
   {
    id: "workDuration",
    header: "Work Duration",
    accessorKey: "workDuration",
    headerAlign: "right",
    cell: ({ row }) => {
     const item = row?.original;
     return <div>{getWorkDuration(item?.dateIn, item?.dateResign, true)}</div>;
    },
   },
   getColumn("Date Resigned", "dateResign", "right", false),
   getColumn("Reason Resign", "reasonResign", "left", false),
  ],
  []
 );
 if (!employees?.length) return null;

 return (
  <Modal
   show={visible}
   onHide={onClose}
   size="xl"
   dialogClassName="modal-responsive"
   style={{ left: "20px" }}
   className="d-flex justify-content-center"
  >
   <Modal.Header closeButton>
    <Modal.Title>{Title}</Modal.Title>
   </Modal.Header>
   <Modal.Body>
    {employees?.length > 0 ? (
     <ReusableTable columns={columns} data={employees} />
    ) : (
     <p className="text-center">No employee data available.</p>
    )}

    <Button variant="secondary" onClick={onClose}>
     Close
    </Button>
   </Modal.Body>
  </Modal>
 );
};

export default EmployeeSummaryModal;
