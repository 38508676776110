import React, { useState, useEffect } from "react";
import {
 Form,
 Row,
 Col,
 Container,
 Button,
 Card,
 Spinner,
 ToggleButtonGroup,
 ToggleButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import { getSheetDetails } from "../actions/sheetActions";
import * as XLSX from "xlsx";
import {
 postAttendance,
 getLatestAttendance,
} from "../actions/attendanceActions";
import { logout } from "../actions/userActions";
import { capitalizeEachWord, stringIncludes } from "../utils/utils";
import LatestEmployeeUpdated from "../components/employee/LatestEmployeeUpdated";
import { useQuery } from "@tanstack/react-query";
import {
 getLatestEmployeeSummary,
 updateEmployeeSummary,
} from "../actions/employeeSummaryActions";
import LoadingErrorHandler from "../components/LoadingErrorHandler";
import Popup from "../components/Popup";
import { getMonthNow, getYearNow } from "../utils/time";
import SettingsUpdateForm from "./SettingsUpdateForm";
import { getOutlets } from "../actions/outletActions";
import dayjs from "dayjs";
import LatestAttendanceUpdated from "../components/employee/LatestAttendanceUpdated";
import { DatePicker } from "rsuite";
import IButton from "../components/IButton";
import {
 getLocalStorageSettings,
 updateLocalStorageSettings,
} from "../utils/localStorage.jsx";
//import { updateBankAccounts } from "../actions/midtransActions";

const SettingsView = ({ history }) => {
 const {
  loading: userLoading,
  error: userError,
  userInfo,
 } = useSelector((state) => state.userLogin);
 const token = userInfo?.token;

 const [message, setMessage] = useState(null);
 const [selectedFile, setSelectedFile] = useState(null);
 const [fileType, setFileType] = useState("dat");
 const [showDebug, setShowDebug] = useState(
  userInfo?.isAdmin
   ? getLocalStorageSettings("settings", "showDebug") ?? false
   : false
 );

 const dateNow = dayjs();
 const [startDate, setStartDate] = useState(
  dateNow?.subtract(1, "month")?.set("date", 26)?.startOf("day")?.toISOString()
 );

 const [endDate, setEndDate] = useState(
  dateNow?.get("date") > 25
   ? dateNow?.set("date", 25)?.endOf("day")?.toISOString()
   : dateNow?.subtract(1, "day")?.endOf("day")?.toISOString()
 );
 const [showUpdatePopup, setShowUpdatePopup] = useState(false);
 const initialItem = {
  month: getMonthNow() ?? 0,
  year: getYearNow() ?? 0,
 };
 const [item, setItem] = useState(initialItem);
 const [attendanceResult, setAttendanceResult] = useState({
  attendance: null,
  message: null,
 });
 //console.log({ item });

 const [selectedOutlet, setSelectedOutlet] = useState("ALL OUTLETS"); // Misalnya outlet default
 const [progressUpdateSummary, setProgressUpdateSummary] = useState({
  progress: 0,
  total: 0,
  outlet: "",
 });
 const [employeeSummary, setEmployeeSummary] = useState({
  data: {},
  progress: 0,
  total: 0,
  isLoading: false,
  error: null,
 });

 const addNewEmployeeData = (newData) => {
  setEmployeeSummary((prevState) => ({
   ...prevState,
   data: {
    ...prevState.data,
    ...newData, // Menambahkan data baru tanpa menghapus data lama
   },
  }));
 };

 const dispatch = useDispatch();

 const attendanceDataEnter = useSelector((state) => state.attendanceDataEnter);
 const {
  progress,
  success,
  loading: loadingAttendanceDataEnter,
  error: errorAttendanceDataEnter,
  attendances,
 } = attendanceDataEnter;

 const { data: dataQueryGetOutlets, error: errorQueryGetOutlets } = useQuery({
  queryKey: ["getOutlets", {}], // Query key as an array
  queryFn: getOutlets, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const outlets = dataQueryGetOutlets?.outlets || [];
 const errorGetOutlets =
  errorQueryGetOutlets?.response?.data?.message ||
  errorQueryGetOutlets?.message;

 const { refetch: fetchUpdateEmployeeSummary } = useQuery({
  queryKey: [
   "updateEmployeeSummary",
   { month: item?.month, year: item?.year, outlet: selectedOutlet },
  ], // Query key as an array
  queryFn: updateEmployeeSummary, // Function to fetch data
  enabled: false, // Disable automatic fetching
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const handleFetchEmployeeSummary = async () => {
  if (!outlets || outlets.length === 0) return;
  // Tambahkan outlet "ALL OUTLETS"
  outlets.push({
   lateInTolerance: 0,
   _id: "all_outlets", // ID unik
   name: "ALL OUTLETS",
   label: "All Outlets",
   type: "all",
  });

  const totalOutlets = outlets.length; // Total outlet + 1 "ALL OUTLETS"
  //console.log(outlets);
  for (let index = 0; index <= totalOutlets; index++) {
   const outlet = outlets[index];
   const progress = index + 1;
   try {
    if (!outlet) continue;

    console.log(
     `(${progress}/${totalOutlets}) Fetching data for: ${outlet?.name}`
    );
    setSelectedOutlet(outlet?.name);
    setProgressUpdateSummary({
     progress: progress,
     total: totalOutlets,
     outlet: outlet?.name,
    });

    // Panggil fungsi query secara manual dan tunggu hingga selesai
    const { data } = await fetchUpdateEmployeeSummary({
     queryKey: [
      "updateEmployeeSummary",
      { month: item?.month, year: item?.year, outlet: outlet?.name },
     ],
    });

    addNewEmployeeData(data);

    console.log(
     `(${progress}/${totalOutlets}) Successfully fetched data for: ${capitalizeEachWord(
      outlet?.name
     )}`
    );
   } catch (error) {
    console.error(
     `(${progress}/${totalOutlets}) Error fetching data for ${capitalizeEachWord(
      outlet?.name
     )}:`,
     error
    );
   }
  }
 };

 const {
  data: dataSheet,
  isLoading: isLoadingSheet,
  error: errorSheet,
 } = useQuery({
  queryKey: ["getSheetDetails", { name: "latestEmployeeUpdate" }], // Query key as an array
  queryFn: getSheetDetails, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const sheet = dataSheet || dataSheet?.sheet || [];

 const { data: dataLatestEmployeeSummary, error: errorLatestEmployeeSummary } =
  useQuery({
   queryKey: ["getLatestEmployeeSummary", {}], // Query key as an array
   queryFn: getLatestEmployeeSummary, // Function to fetch data
   enabled: token ? true : false, // Only fetch if token is available
   staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
   refetchInterval: 1000 * 30,
   retry: false, // Disable retries on failure
   refetchOnError: false, // Disable automatic refetch when there's an error
  });
 const latestEmployeeSummary = dataLatestEmployeeSummary || [];

 const { data: dataLatestAttendance, error: errorLatestAttendance } = useQuery({
  queryKey: ["getLatestAttendance", {}], // Query key as an array
  queryFn: getLatestAttendance, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 360, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 60,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });
 const latestAttendance = dataLatestAttendance || [];

 //const {
 // refetch: fetchUpdateBankAccounts,
 // error: errorUpdateBankAccounts,
 // data: dataUpdateBankAccounts,
 //} = useQuery({
 // queryKey: ["updateBankAccounts", {}], // Query key as an array
 // queryFn: updateBankAccounts, // Function to fetch data
 // enabled: false, // Disable automatic fetching
 // retry: false, // Disable retries on failure
 // refetchOnError: false, // Disable automatic refetch when there's an error
 //});

 const error =
  errorSheet?.response?.data?.message ||
  errorSheet?.message ||
  errorAttendanceDataEnter ||
  errorLatestEmployeeSummary?.response?.data?.message ||
  errorLatestEmployeeSummary?.message ||
  errorLatestAttendance?.response?.data?.message ||
  errorLatestAttendance?.message;
 //errorUpdateBankAccounts?.response?.data?.message ||
 //errorUpdateBankAccounts?.message;

 useEffect(() => {
  if (
   (!userLoading && !userInfo) ||
   stringIncludes(userError, "not authorized") ||
   stringIncludes(error, "not authorized")
  ) {
   setMessage(`Authorization failed!, please login again`);
   dispatch(logout());
   history.push("/login");
  }
  if (userInfo?.isAdmin) {
   //if (isExecuteGoogleFinished) {
   // setMessage(
   //  "Import All Employees from Google Spreadsheet to server database have done!"
   // );
   //}
   if (success && progress === 100) {
    setMessage("Upload attendance file complete!");
   }
   if (error || errorAttendanceDataEnter) {
    setMessage(error);
   }
   //if (employeeSummary?.data?.summaryID && !employeeSummary?.data?.isLoading) {
   // setMessage(
   //  `Update employee summary ${employeeSummary?.data?.summaryID} complete!`
   // );
   //}
   if (
    progressUpdateSummary?.progress &&
    progressUpdateSummary?.progress === progressUpdateSummary?.total &&
    employeeSummary?.data
   ) {
    setMessage(`Update employee summary complete!`);
    setProgressUpdateSummary(null);
   }
  }
  // eslint-disable-next-line
 }, [
  dispatch,
  history,
  userInfo,
  success,
  employeeSummary,
  progressUpdateSummary,
  message,
  latestEmployeeSummary,
  loadingAttendanceDataEnter,
  progress,
 ]);

 // Menggunakan useEffect untuk melihat perubahan nilai endDate
 useEffect(() => {
  if (startDate) {
   //console.log({ startDate });
  }
  if (endDate) {
   //console.log({ endDate });
  }
 }, [startDate, endDate]); // Efek ini akan dijalankan setiap kali endDate berubah

 useEffect(() => {
  if (attendances) {
   setAttendanceResult(attendances);
  }
 }, [attendances]);

 useEffect(() => {
  if (userInfo?.isAdmin) {
   updateLocalStorageSettings("settings", "showDebug", showDebug);
  }
 }, [showDebug, userInfo?.isAdmin]);

 const updateHandler = (e) => {
  e?.preventDefault();
  handleFetchEmployeeSummary();
  //fetchUpdateBankAccounts();
 };

 const handleFileChange = (event) => {
  setSelectedFile(event.target.files[0]);
 };

 const handleFileUpload = () => {
  if (!selectedFile) {
   alert("No file selected!");
   return;
  }
  if (progress > 0 && progress < 100) {
   alert("Upload in progress!");
   return;
  }
  const reader = new FileReader();
  const formattedStartDate = dayjs(startDate)?.startOf("day"); // Mulai dari awal hari (00:00:00);
  const formattedEndDate = dayjs(endDate)?.endOf("day"); // Sampai akhir hari (23:59:59)

  if (stringIncludes(fileType, "excel")) {
   reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    if (!data) return;

    const workbook = XLSX.read(data, { type: "array" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const json = XLSX.utils.sheet_to_json(worksheet);
    console.log({ json });

    // Filter json berdasarkan rentang startDate dan endDate
    const filteredData = json.filter((item) => {
     const clockDate = dayjs(item?.Waktu); // Parse tanggal clock dari JSON
     return clockDate?.isBetween(
      formattedStartDate,
      formattedEndDate,
      null,
      "[]"
     ); // Menyaring data yang berada dalam rentang waktu
    });

    if (!filteredData?.length) {
     console.error(`filteredData is not valid ${filteredData}`);
     return;
    }

    dispatch(postAttendance(filteredData, fileType)); // Use json directly here
   };
  } else if (stringIncludes(fileType, "dat")) {
   reader.onload = (e) => {
    const arrayBuffer = e.target.result; // Ini adalah ArrayBuffer
    const text = new TextDecoder().decode(arrayBuffer); // Mengonversi ArrayBuffer menjadi string
    if (!text?.length) {
     console.error(`text is not valid string ${text}`);
     console.error({ text });
     return;
    }

    // Pecah teks menjadi array berdasarkan baris
    const lines = text?.trim()?.split("\n");
    if (!lines?.length) {
     console.error(`lines is not valid string ${lines}`);
     return;
    }

    // Ubah setiap baris menjadi objek JSON
    const json = lines?.map((line) => {
     if (typeof line !== "string") return null;

     const columns = line?.trim()?.split(/\s+/); // Pecah berdasarkan spasi/tab
     if (!columns[1] || !columns[2]) return null;

     const clock = dayjs(columns[1] + " " + columns[2])?.toISOString();
     return {
      employeeID: parseInt(columns[0]), // Kolom 1: Employee ID
      clock: clock, // Kolom 2 dan 3: Tanggal dan Waktu
      date: clock,
      deviceID: parseInt(columns[3]), // Kolom 4:
      attendanceType: parseInt(columns[4]), // Kolom 5
      dataType: parseInt(columns[5]), // Kolom 6
     };
    });

    // Filter json berdasarkan rentang startDate dan endDate
    const filteredData = json.filter((item) => {
     const clockDate = dayjs(item?.clock); // Parse tanggal clock dari JSON

     // Debugging untuk memastikan tanggal yang diambil dari JSON
     console.log("Clock Date:", clockDate.format("YYYY-MM-DD HH:mm:ss"));

     // Pastikan clockDate valid sebelum membandingkan
     if (!clockDate.isValid()) {
      console.log("Invalid clock date:", item?.clock);
      return false; // Jika tanggal tidak valid, jangan masukkan ke filteredData
     }

     return clockDate.isBetween(
      formattedStartDate,
      formattedEndDate,
      null,
      "[]"
     ); // Menyaring data yang berada dalam rentang waktu
    });

    console.log(`startDate: ${startDate}, endDate: ${endDate}`);
    // Debugging hasil filter
    console.log("Filtered Data:", filteredData);

    if (!filteredData?.length) {
     console.error(`filteredData is not valid ${filteredData}`);
     return;
    }

    console.log({ filteredData });

    // Kirim data ke Redux
    dispatch(postAttendance(filteredData, fileType));
   };
  }

  reader.readAsArrayBuffer(selectedFile);
 };

 const handleCloseUpdatePopup = (event) => {
  if (event) {
   event.preventDefault();
  }

  setShowUpdatePopup(false);
 };

 const handleShowUpdatePopup = (event) => {
  if (event) {
   event.preventDefault(); // Prevent the default action
  }

  setShowUpdatePopup(true);
 };

 const submitUpdatePopupHandler = async (event) => {
  event?.preventDefault(); // Mencegah halaman refresh
  if (!item) return;

  updateHandler(event);
  setShowUpdatePopup(false);
 };

 return (
  <Container>
   <h2>Settings</h2>
   {message && <Message>{message}</Message>}
   {
    <>
     {sheet ? (
      <>
       {/* <Row style={{ fontSize: "80%" }}>
        <Col md="auto">Last updated Googlesheet:</Col>
        <Col md="auto">
         {"at "}
         {sheet.lastModifiedTime ? (
          dayjs(sheet.lastModifiedTime).format("D-MMM-YYYY HH:mm:ss wib")
         ) : (
          <></>
         )}
        </Col>
       </Row>*/}

       <Row style={{ fontSize: "80%" }}>
        <LatestEmployeeUpdated sheet={sheet} />
       </Row>
      </>
     ) : (
      <></>
     )}
     <Form.Label className="mt-4">
      Update monthly employee summary
      <Row>
       <Col>
        {latestEmployeeSummary?.updatedAt &&
         `Latest employee summary updated at: ${dayjs(
          latestEmployeeSummary?.updatedAt
         ).format("YYYY-MM-DD HH:mm:ss wib")} (${capitalizeEachWord(
          latestEmployeeSummary?.outlet
         )})`}
       </Col>
      </Row>
      <Row>
       <Col>
        {progressUpdateSummary?.progress > 0 &&
         progressUpdateSummary?.progress < progressUpdateSummary?.total && (
          <p>{`Updating... (${progressUpdateSummary?.progress}/${progressUpdateSummary?.total}) for ${progressUpdateSummary?.outlet}`}</p>
         )}
       </Col>
      </Row>
     </Form.Label>

     <Row>
      <Col>
       <Button
        variant="danger"
        onClick={handleShowUpdatePopup}
        disabled={
         progressUpdateSummary?.progress > 0 &&
         progressUpdateSummary?.progress < progressUpdateSummary?.total
          ? true
          : false
        }
       >
        {progressUpdateSummary?.progress > 0 &&
        progressUpdateSummary?.progress < progressUpdateSummary?.total ? (
         <>
          <Spinner
           as="span"
           animation="border"
           size="sm"
           role="status"
           aria-hidden="true"
           style={{ marginRight: "5px" }}
          />
          {`Updating... ${Math.round(
           (progressUpdateSummary?.progress / progressUpdateSummary?.total) *
            100
          )}%`}
         </>
        ) : (
         `Update`
        )}
       </Button>
      </Col>
     </Row>
     <Row className="mt-3 mb-3">
      <Col md="auto">
       <Form.Group controlId="formFile">
        <Form.Label>
         Import attendance data from {fileType} file (
         {stringIncludes(fileType, "dat") ? ".dat" : "format .xlsx or .xls"})
         <Row>
          <LatestAttendanceUpdated attendance={latestAttendance} />
         </Row>
        </Form.Label>
        <Row>
         <Col md="auto">
          <ToggleButtonGroup type="radio" name="options">
           <ToggleButton
            key="dat"
            id="fileTypeRadio1"
            value="dat"
            name="radio"
            onChange={(e) => setFileType(e.target.value)}
            style={{
             backgroundColor: stringIncludes(fileType, "dat")
              ? "#dc3545"
              : "#6c757d",
             border: "none", // Remove the outline
            }}
            disabled={progress > 0 && progress < 100}
           >
            dat
           </ToggleButton>
           <ToggleButton
            key="excel"
            id="fileTypeRadio2"
            value="excel"
            name="radio"
            onChange={(e) => setFileType(e.target.value)}
            style={{
             backgroundColor: !stringIncludes(fileType, "dat")
              ? "#dc3545"
              : "#6c757d",
             border: "none", // Remove the outline
            }}
            disabled={progress > 0 && progress < 100}
           >
            excel
           </ToggleButton>
          </ToggleButtonGroup>
         </Col>
         <Col md="auto">
          <Form.Label>Start date: </Form.Label>
          <DatePicker
           format="dd-MM-yyyy"
           placeholder={startDate ? dayjs(startDate).format("DD-MM-YYYY") : ""}
           onChange={(date) => {
            if (!date) return;

            const parsedDate = dayjs(date);
            if (parsedDate.isValid()) {
             setStartDate(parsedDate?.startOf("day")?.toISOString());
            } else {
             console.error("Invalid date:", parsedDate);
            }
           }}
           ranges={[]}
           disabled={progress > 0 && progress < 100}
          />
         </Col>
         <Col md="auto">
          <Form.Label>End date: </Form.Label>
          <DatePicker
           format="dd-MM-yyyy"
           placeholder={endDate ? dayjs(endDate).format("DD-MM-YYYY") : ""}
           onChange={(date) => {
            if (!date) return;

            const parsedDate = dayjs(date);
            if (parsedDate.isValid()) {
             setEndDate(parsedDate?.endOf("day").toISOString());
            } else {
             console.error("Invalid date:", parsedDate);
            }
           }}
           ranges={[]}
           disabled={progress > 0 && progress < 100}
          />
         </Col>
        </Row>
        <Form.Control
         type="file"
         accept={stringIncludes(fileType, "dat") ? ".dat" : ".xls,.xlsx"}
         onChange={handleFileChange}
         className="mt-2"
         disabled={progress > 0 && progress < 100}
        />
       </Form.Group>
      </Col>
     </Row>
     <Row>
      <Col>
       <IButton
        iconClassName="fas fa-upload"
        progress={progress}
        onClick={handleFileUpload}
        label="Upload"
        loadingLabel="Uploading..."
       />
      </Col>
     </Row>
     {userInfo?.isAdmin && (
      <Row className="mt-3">
       <Col>
        <Button
         variant={showDebug ? "secondary" : "danger"}
         onClick={() => {
          setShowDebug(!showDebug);
         }}
        >
         {`${showDebug ? "Hide" : "Show"} debug`}
        </Button>
       </Col>
      </Row>
     )}

     <Popup
      show={showUpdatePopup}
      onHide={handleCloseUpdatePopup}
      title="Update Employee Summary"
      onApply={submitUpdatePopupHandler}
      applyLabel="Update"
     >
      <SettingsUpdateForm
       formData={item}
       onChange={(updatedData) => {
        setItem(updatedData);
       }}
       onSubmit={submitUpdatePopupHandler}
      />
     </Popup>
     <LoadingErrorHandler
      loading={employeeSummary?.isLoading || isLoadingSheet}
      error={errorGetOutlets || employeeSummary?.error}
     >
      {showDebug && employeeSummary?.data?.summaryID && (
       <Card
        className="my-3 p-3 rounded"
        style={{ minWidth: "50%", maxWidth: "500px" }}
       >
        <Card.Body>
         <Row>
          <Col>
           <p>Update employee summary response</p>
          </Col>
         </Row>
         <Row>
          <Col md="auto">
           <pre>{JSON.stringify(employeeSummary?.data, null, 2)}</pre>
          </Col>
         </Row>
        </Card.Body>
       </Card>
      )}
      {showDebug && attendanceResult?.attendances?.length && (
       <Card
        className="my-3 p-3 rounded"
        style={{ minWidth: "50%", maxWidth: "800px" }}
       >
        <Card.Body>
         <Row>
          <Col>
           <p>Upload Attendance response</p>
          </Col>
         </Row>
         <Row>
          <Col md="auto">
           <ol>
            {attendanceResult?.attendances?.map((item) => {
             return <li>{item?.message}</li>;
            })}
           </ol>
          </Col>
         </Row>
        </Card.Body>
       </Card>
      )}
     </LoadingErrorHandler>
    </>
   }
  </Container>
 );
};

export default SettingsView;
