import React from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";

const SettingsUpdateForm = ({
 formData = {},
 onChange = () => {},
 onSubmit = () => {},
}) => {
 if (!formData) return null;

 const { year = dayjs().year(), month = dayjs().month() } = formData;

 // Fungsi untuk menangani perubahan input
 const handleChange = (field, value) => {
  if (formData[field] !== value) {
   onChange({ ...formData, [field]: value });
  }
 };

 return (
  <Form onSubmit={onSubmit}>
   <Row>
    <Col>
     <Form.Group controlId="year">
      <Form.Label>Employee Summary Year</Form.Label>
      <Form.Control
       type="number"
       placeholder={`Year e.g ${dayjs().year()}`}
       value={year}
       onChange={(e) => handleChange("year", e.target.value)}
       onWheel={(e) => e.target.blur()}
       required
      />
     </Form.Group>
    </Col>
    <Col>
     <Form.Group controlId="month">
      <Form.Label>Month</Form.Label>
      <Form.Select
       value={month}
       onChange={(e) => handleChange("month", e.target.value)}
       required
      >
       {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
        return (
         <option key={month} value={month}>
          {dayjs()
           .month(month - 1)
           .format("MMMM")}
         </option>
        );
       })}
      </Form.Select>
     </Form.Group>
    </Col>
   </Row>
  </Form>
 );
};

// Validasi props menggunakan PropTypes
SettingsUpdateForm.propTypes = {
 formData: PropTypes.shape({
  outletID: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.string,
 }),
 onChange: PropTypes.func.isRequired,
 onSubmit: PropTypes.func.isRequired,
};

export default SettingsUpdateForm;
