import dayjs from "dayjs";
import verifiedIcon from "../Assets/icon/verified.png";
import { Link } from "react-router-dom"; // Pastikan import Link dari react-router-dom

const duration = require("dayjs/plugin/duration");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(duration);
dayjs.extend(relativeTime);
var isBetween = require("dayjs/plugin/isBetween");
// import isBetween from 'dayjs/plugin/isBetween' // ES 2015
dayjs.extend(isBetween);

function getAxiosTargetPath(targetPath) {
 let axiosPath = targetPath;
 console.log(`GET ${axiosPath}`);
 return axiosPath;
}

function getAge(birthDate, isUsingVarName = true, monthOnly = false) {
 if (!birthDate || !dayjs(birthDate).isValid()) {
  return;
 }

 const now = dayjs();
 const birth = dayjs(birthDate).subtract(1, "days");

 // Menghitung umur dalam tahun, bulan, dan hari
 const years = now.diff(birth, "year");
 const months = now.diff(birth.add(years, "years"), "month");
 const days = now.diff(birth.add(years, "years").add(months, "months"), "day");
 if (monthOnly) {
  if (isUsingVarName) {
   return `Age: ${years} Years ${months} Months`;
  }
  return `${years} Years ${months} Months`;
 }

 return isUsingVarName
  ? `Age: ${years} Years ${months} Months ${days} Days`
  : `${years} Years ${months} Months ${days} Days`;
}

function getAgeMonth(birthDate) {
 if (!birthDate || !dayjs(birthDate).isValid()) {
  return;
 }
 const now = dayjs();
 const birth = dayjs(birthDate).subtract(1, "days") || dayjs("1950-01-01");

 // Hitung perbedaan tahun dan bulan
 const years = now.diff(birth, "year");
 const months = now.diff(birth.add(years, "year"), "month");

 return `Age: ${years} Years ${months} Months`;
}

function getWorkDuration(joinDate, dateResign, simple = false) {
 if (!joinDate) return "";
 const now = dayjs(dateResign).isValid() ? dayjs(dateResign) : dayjs();
 const join = dayjs(joinDate).subtract(1, "days");

 // Menghitung durasi kerja dalam tahun, bulan, dan hari
 const years = now.diff(join, "year");
 const months = now.diff(join.add(years, "years"), "month");
 const days = now.diff(join.add(years, "years").add(months, "months"), "day");

 // Format output berdasarkan parameter simple
 return simple
  ? `${years} thn ${months} bln ${days} hr`
  : `${years} Years ${months} Months ${days} Days`;
}

const options = (arrayOptionAnswers) => {
 return arrayOptionAnswers.map((option, index) => (
  <option key={option + index} value={option}>
   {option}
  </option>
 ));
};

function capitalizeEachWord(str) {
 if (typeof str !== "string" || !str) {
  //console.error("Input must be a string,", " capitalizeEachWord: ", str);
  return str;
 }

 return str
  .split(" ") // Split the string into words
  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lower case the rest
  .join(" "); // Join the words back into a string
}

function truncateString(str, num = 20) {
 return str.length > num ? str.substring(0, num) + "..." : str;
}

// Fungsi untuk mengonversi detik menjadi jam dan menit
function convertSeconds(durationInSeconds) {
 const hours = Math.floor(durationInSeconds / 3600);
 const minutes = Math.floor((durationInSeconds % 3600) / 60);

 return { hours, minutes };
}

const countActiveEmployee = (employees, isActive, outlet = null) => {
 if (!employees?.length) {
  return 0;
 }
 if (!outlet || outlet === "ALL OUTLETS") {
  switch (isActive) {
   case true:
    return employees?.filter(
     (employee) => !employee?.dateResign && employee?.status !== "OUTSOURCING"
    )?.length;
   case false:
    return employees?.filter(
     (employee) => employee?.dateResign && employee?.status !== "OUTSOURCING"
    )?.length;
   default:
    return employees?.length;
  }
 }
 const filteredEmployees = employees?.filter(
  (employee) =>
   employee?.outlet === outlet && employee?.status !== "OUTSOURCING"
 );

 switch (isActive) {
  case true:
   return filteredEmployees?.filter((employee) => !employee?.dateResign)
    ?.length;
  case false:
   return filteredEmployees?.filter((employee) => employee?.dateResign)?.length;
  default:
   return filteredEmployees?.length;
 }
};

const countStatusEmployee = (employees, isPermanent, outlet = null) => {
 if (!Array.isArray(employees)) {
  return 0;
 }

 const isValidString = (status) => typeof status === "string";

 const filterByStatus = (employeesList) => {
  return employeesList.filter((employee) => {
   if (!isValidString(employee?.status)) return false;
   return isPermanent
    ? employee?.status.toLowerCase().replace(/\s+/g, "") === "tetap" &&
       !employee?.dateResign
    : employee?.status.toLowerCase().replace(/\s+/g, "") !== "tetap" &&
       !employee?.dateResign;
  }).length;
 };

 if (!outlet || outlet === "ALL OUTLETS") {
  return filterByStatus(employees);
 }

 const filteredEmployees = employees?.filter(
  (employee) => employee?.outlet === outlet
 );
 return filterByStatus(filteredEmployees);
};

const stringIncludes = (string = "", search = "") => {
 if (typeof string !== "string" || !string) return false;
 if (typeof search !== "string") return false; // Check if search is a string

 return string?.toLowerCase().includes(search?.toLowerCase());
};

const toLowerCaseSafe = (value) => {
 if (typeof value !== "string" || !value) {
  return ""; // Return an empty string if the value is not a string
 }
 return value?.toLowerCase();
};

const attendanceText = (employee, attendance, gracePeriod = 5) => {
 if (!attendance || !employee) {
  return "-";
 }
 if (!attendance.clockIn && !attendance.clockOut && attendance.status) {
  return attendance.status;
 }
 if (attendance.clockIn && attendance.clockOut) {
  const clockIn = dayjs(attendance.clockIn);
  const clockOut = dayjs(attendance.clockOut);

  // Hitung keterlambatan
  const lateMinutes = calculateLate(
   employee,
   clockIn,
   gracePeriod,
   attendance.status
  );

  return `${clockIn.format("HH:mm")} - ${clockOut.format("HH:mm")}${
   lateMinutes ? ` Late: ${lateMinutes} mins` : ""
  }`;
 }
 return "";
};

const calculateLate = (
 employee,
 clockIn,
 gracePeriod = 0,
 status = "hadir"
) => {
 if (
  !employee ||
  !clockIn ||
  stringIncludes(status, "hanya lembur") ||
  stringIncludes(status, "in class training")
 ) {
  return 0;
 }

 const cIn = dayjs(clockIn);
 if (!cIn.isValid()) {
  return 0;
 }

 const outlet = employee?.outlet;
 const position = employee?.position;

 if (!outlet || !position) {
  return 0;
 }

 if (typeof outlet !== "string" || typeof position !== "string") {
  return 0;
 }
 const plainSchedule = getValidSchedule(employee, clockIn);
 let schedule = null;
 if (plainSchedule) {
  schedule = dayjs(plainSchedule);
 }

 // Jadwal shift
 //const shiftSchedules = {
 // morning: cIn.hour(6).minute(0), // 06:00
 // middle: cIn.hour(11).minute(0), // 11:00
 // evening: cIn.hour(14).minute(0), // 14:00
 // morningKhalid: cIn.hour(8).minute(0), // 08:00 untuk outlet Khalid
 // morningDriver: cIn.hour(9).minute(0),
 // morningKepalaGudang: cIn.hour(9).minute(0),
 // midnight: cIn.hour(23).minute(59), // 17:00 untuk outlet Khalid
 //};

 let shiftStart;

 //if (!schedule) {
 // // Tentukan shift berdasarkan jadwal clockIn yang valid
 // if (!outlet.toLowerCase().includes("khalid")) {
 //  if (
 //   cIn.isBetween(cIn.hour(6).minute(0), cIn.hour(10).minute(59), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.morning;
 //  } else if (
 //   cIn.isBetween(cIn.hour(11).minute(0), cIn.hour(13).minute(59), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.middle;
 //  } else if (
 //   cIn.isBetween(cIn.hour(14).minute(0), cIn.hour(23).minute(0), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.evening;
 //  }
 // } else if (outlet.toLowerCase().includes("khalid")) {
 //  // Tentukan shift untuk outlet Khalid
 //  if (
 //   !position.toLowerCase().includes("driver") &&
 //   outlet.toLowerCase().includes("khalid medan") &&
 //   cIn.isBetween(cIn.hour(8).minute(0), cIn.hour(10).minute(59), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.morningKhalid;
 //  } else if (
 //   position.toLowerCase().includes("driver") &&
 //   cIn.isBetween(cIn.hour(9).minute(0), cIn.hour(10).minute(59), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.morningDriver;
 //  } else if (
 //   position.toLowerCase().includes("kepala gudang") &&
 //   outlet.toLowerCase().includes("khalid bintaro") &&
 //   cIn.isBetween(cIn.hour(9).minute(0), cIn.hour(10).minute(59), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.morningKepalaGudang;
 //  } else if (
 //   !position.toLowerCase().includes("kepala gudang") &&
 //   !position.toLowerCase().includes("driver") &&
 //   outlet.toLowerCase().includes("khalid bintaro") &&
 //   cIn.isBetween(cIn.hour(8).minute(0), cIn.hour(10).minute(59), "minute")
 //  ) {
 //   shiftStart = shiftSchedules.morningKhalid;
 //  }
 // }
 //
 // if (
 //  position.toLowerCase().includes("security") ||
 //  outlet.toLowerCase().includes("management")
 // ) {
 //  shiftStart = shiftSchedules.midnight;
 // }
 //}

 if (schedule) {
  shiftStart = schedule;
  //console.log("schedule: ", schedule);
 }

 if (!shiftStart) {
  return 0;
 }

 // Hitung keterlambatan dalam menit
 const lateInMinutes =
  cIn.diff(schedule || shiftStart, "minutes") - gracePeriod;

 // Hanya hitung keterlambatan jika positif
 return lateInMinutes > 0 && lateInMinutes <= 60 ? lateInMinutes : 0;
};

const getValidSchedule = (employee, clockIn) => {
 if (!employee || !clockIn) {
  return 0;
 }

 // Check if there are no clockIn times
 if (!employee?.clockIn && !employee?.clockIn2 && !employee?.clockIn3) {
  return 0;
 }

 const cIn = dayjs(clockIn);

 // Create an array of valid schedules
 const validSchedules = [
  employee?.clockIn,
  employee?.clockIn2,
  employee?.clockIn3,
 ]
  .filter((c) => c)
  .map((c) => dayjs(c));

 // Return 0 if no valid schedules exist
 if (validSchedules.length === 0) {
  return 0;
 }

 // Check each valid schedule
 for (const schedule of validSchedules) {
  const startOfSchedule = schedule;
  const endOfSchedule = schedule.add(2, "hours"); // Adjust to 2 hours

  // Check if clockIn falls within the schedule range
  if (isTimeBetween(cIn, startOfSchedule, endOfSchedule)) {
   return setHour(cIn, schedule) || 0; // Return the valid schedule as a date
  }
 }

 return 0; // Return 0 if no valid schedule matches
};

const setHour = (day, hour) => {
 if (!day || !hour) {
  return null;
 }
 const hari = dayjs(day);
 const jam = dayjs(hour);
 return dayjs(`${hari.format("YYYY-MM-DD")} ${jam.format("HH:mm")}`).toDate();
};

// Fungsi untuk membandingkan waktu tanpa memperhatikan tanggal
function isTimeBetween(timeToCheck, startTime, endTime) {
 const check = dayjs(timeToCheck, "HH:mm");
 const start = dayjs(
  `${check.format("YYYY-MM-DD")} ${dayjs(startTime).format("HH:mm")}`
 );
 const end = dayjs(
  `${check.format("YYYY-MM-DD")} ${dayjs(endTime).format("HH:mm")}`
 );

 return check.isBetween(start, end, "minute");
}

const getTimeAgo = (date, isLastSeen = false) => {
 if (!date) return "";

 const now = dayjs();
 const time = dayjs(date);

 const diffInSeconds = now.diff(time, "second");
 const diffInMinutes = now.diff(time, "minute");
 const diffInHours = now.diff(time, "hour");
 const diffInDays = now.diff(time, "day");
 const diffInMonths = now.diff(time, "month");
 const diffInYears = now.diff(time, "year");

 if (isLastSeen) {
  // Jika aktif dalam 15 detik terakhir, dianggap "Active now"
  if (diffInSeconds < 15) {
   return (
    <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
     <i
      className="fas fa-circle"
      style={{ color: "green", fontSize: "8px" }}
     ></i>
     <span>Active now</span>
    </span>
   );
  } else if (diffInSeconds < 60 && diffInMinutes < 1) {
   return `Last seen in ${diffInSeconds} secs ago`;
  } else if (diffInMinutes < 60) {
   return `Last seen in ${diffInMinutes} mins ago`;
  } else if (diffInHours < 24) {
   return `Last seen in ${diffInHours} hrs ago`;
  } else if (diffInDays < 30) {
   return `Last seen in ${diffInDays} days ago`;
  } else if (diffInMonths < 12) {
   return `Last seen in ${diffInMonths} months ago`;
  } else {
   return `Last seen in ${diffInYears} years ago`;
  }
 } else {
  if (diffInSeconds < 60 && diffInMinutes < 1) {
   return `${diffInSeconds} secs ago`;
  } else if (diffInMinutes < 60) {
   return `${diffInMinutes} mins ago`;
  } else if (diffInHours < 24) {
   return `${diffInHours} hrs ago`;
  } else if (diffInDays < 30) {
   return `${diffInDays} days ago`;
  } else if (diffInMonths < 12) {
   return `${diffInMonths} months ago`;
  } else {
   return `${diffInYears} years ago`;
  }
 }
};

function getOutletColumn(outlet) {
 return stringIncludes(outlet, "all outlets")
  ? [
     {
      id: outlet, // Menambahkan id unik
      header: "Outlet",
      accessorKey: "outlet",
      cell: ({ row }) => {
       const item = row.original;
       return <div>{capitalizeEachWord(item.outlet)}</div>;
      },
     },
    ]
  : [];
}

const getNumberColumn = (headerAlign = "right") => {
 return [
  {
   id: "no",
   header: "No.",
   accessorKey: "no",
   headerAlign: headerAlign,
   cell: ({ row }) => <div>{row?.index + 1}</div>, // Menambahkan nomor urut berdasarkan indeks baris
  },
 ];
};

// Fungsi util untuk membuat kolom
function getIdNameColumns() {
 return [
  {
   id: "employeeID",
   header: "ID",
   accessorKey: "employeeID",
  },
  {
   id: "name",
   header: "Name",
   accessorKey: "name",
   headerAlign: "left",
   cell: ({ row }) => {
    const item = row.original;
    return (
     <Link to={`/employee/${item._id}`} className="link-black">
      {capitalizeEachWord(item?.name)}{" "}
      {stringIncludes(item.status, "tetap") && verifiedIcon && (
       <img
        src={verifiedIcon}
        style={{ width: "15px", height: "15px" }}
        alt="Verified"
       />
      )}
     </Link>
    );
   },
  },
 ];
}

function getAttIdNameColumns() {
 return [
  {
   id: "employeeID",
   header: "ID",
   accessorKey: "employeeID",
  },
  {
   id: "name",
   header: "Name",
   accessorKey: "name",
   headerAlign: "left",
   cell: ({ row }) => {
    const item = row.original;
    return (
     <Link to={`/employee/${item.employee._id}`} className="link-black">
      {capitalizeEachWord(item?.name)}{" "}
      {stringIncludes(item.status, "tetap") && verifiedIcon && (
       <img
        src={verifiedIcon}
        style={{ width: "15px", height: "15px" }}
        alt="Verified"
       />
      )}
     </Link>
    );
   },
  },
 ];
}

// Fungsi util untuk menampilkan kolom dengan data yang diproses
function getColumn(
 header = "Status",
 accessorKey = "status",
 align = "center",
 useCapitalLetter = true, // Menambahkan parameter useCapitalLetter
 cellTransform = null,
 id = null,
 valueInPercent = false,
 decimalPlaces = valueInPercent ? 2 : 0,
 booleanValue = false
) {
 return {
  id: id ? id + accessorKey : accessorKey,
  header: header,
  accessorKey: accessorKey,
  headerAlign: align,
  cell: ({ row }) => {
   const item = row.original;

   // Fungsi untuk mengakses nested property dengan accessorKey
   const getValueFromObject = (obj, key) => {
    return key.split(".").reduce((o, k) => (o ? o[k] : undefined), obj);
   };

   // Akses nilai dari objek dengan accessorKey
   let displayValue = getValueFromObject(item, accessorKey);
   //if (stringIncludes(accessorKey, "attendanceRatio")) {
   // const totalHadir = getValueFromObject(item, "totalHadir");
   // const attdr = totalHadir / 26;
   // const attdrPercentage = attdr * 100;
   // displayValue = `${round(attdrPercentage)}%`;
   //}
   // Pastikan item ada sebelum mencoba mengakses properti
   if (displayValue === undefined || displayValue === null) {
    return <div>-</div>; // Jika tidak ada data, tampilkan tanda hubung atau placeholder
   }

   // Jika field adalah tanggal, format menggunakan dayjs
   if (stringIncludes(accessorKey, "date")) {
    displayValue = dayjs(displayValue)?.format("DD-MMM-YYYY"); 
   }
   if (stringIncludes(accessorKey, "workingDurationInSeconds")) {
    const { hours, minutes } = convertSeconds(displayValue);
    if (!hours && !minutes) {
     return <div>-</div>;
    }
    displayValue = `${hours} hours ${minutes} minutes`;
    return (
     <div>{cellTransform ? cellTransform(displayValue) : displayValue}</div>
    );
   }
   if (
    stringIncludes(accessorKey, "clock") ||
    stringIncludes(accessorKey, "schedule")
   ) {
    displayValue = dayjs(displayValue)?.format("HH:mm"); 
    return (
     <div>{cellTransform ? cellTransform(displayValue) : displayValue}</div>
    );
   }
   if (
    stringIncludes(accessorKey, "clock") ||
    stringIncludes(accessorKey, "schedule")
   ) {
    displayValue = dayjs(displayValue)?.format("HH:mm"); // Sesuaikan format dengan kebutuhan Anda
    return (
     <div>{cellTransform ? cellTransform(displayValue) : displayValue}</div>
    );
   } else if (useCapitalLetter) {
    // Transformasi kapitalisasi jika bukan tanggal
    displayValue = capitalizeEachWord(displayValue);
   }

   // Jika ada transformasi cell yang diberikan, terapkan transformasi tersebut
   if (booleanValue) {
    return (
     <div>{cellTransform ? cellTransform(displayValue) : displayValue}</div>
    );
   }

   return (
    <div>
     {cellTransform
      ? cellTransform(
         decimalPlaces
          ? valueInPercent
            ? round(displayValue, decimalPlaces) + "%"
            : round(displayValue, decimalPlaces)
          : displayValue
        )
      : decimalPlaces
      ? valueInPercent
        ? round(displayValue, decimalPlaces) + "%"
        : round(displayValue, decimalPlaces)
      : displayValue}
    </div>
   );
  },
 };
}

// Fungsi util untuk mendapatkan kolom berdasarkan status isActiveEmployee
function getDateResignColumn(isActiveEmployee, align = "right") {
 return isActiveEmployee
  ? [] // Kembalikan array kosong jika karyawan aktif
  : [
     // Kembalikan kolom "Date Resigned" jika karyawan tidak aktif
     {
      id: "dateResign",
      header: "Date Resigned",
      accessorKey: "dateResign",
      headerAlign: align,
     },
    ];
}

// Contoh penggunaan:
//const lastUpdated = timeAgo("2024-11-14T10:10:00"); // Ubah dengan waktu yang sesuai
//console.log(lastUpdated); // Output: "3 mins ago" atau sesuai dengan waktu yang dihitung

const isValidObject = function (obj) {
 return obj !== null && typeof obj === "object" && Object.keys(obj).length > 0;
};

const getPercentDiff = (dataArray) => {
 // Pastikan array memiliki minimal 2 elemen
 if (dataArray.length >= 2) {
  const currentMonthTotal = dataArray[dataArray.length - 1]; // Bulan ini
  const previousMonthTotal = dataArray[dataArray.length - 2]; // Bulan lalu

  // Menghitung selisih dalam persen
  const difference = currentMonthTotal - previousMonthTotal;
  const percentageDifference = (difference / previousMonthTotal) * 100;

  if (percentageDifference) return percentageDifference?.toFixed(2); // Mengembalikan nilai dengan 2 desimal
  return 0;
 } else {
  return 0;
 }
};

export function isValidNumber(value) {
 return (
  typeof value === "number" && !Number.isNaN(value) && Number.isFinite(value)
 );
}
export const fixNaN = (num) => {
 if (!isValidNumber(num)) {
  return 0;
 }
 return num;
};
const round = (num, decimalPlaces = 0) => {
 if (!isValidNumber(num)) return num; // Return the input if the number is invalid

 const p = Math.pow(10, decimalPlaces);
 const roundedNum = Math.round(num * p) / p;

 // If decimalPlaces is 0, just return the rounded integer
 if (decimalPlaces === 0) {
  return fixNaN(roundedNum);
 }

 // Ensure the number is formatted with the specified decimal places
 const formattedNum = roundedNum.toFixed(decimalPlaces); // Returns a string

 // Return the formatted string instead of converting back to float
 return formattedNum;
};

const isErrorMessage = (message) => {
 if (!message) return false;
 return (
  stringIncludes(message, "error") ||
  stringIncludes(message, "fail") ||
  stringIncludes(message, "not defined") ||
  stringIncludes(message, "not found") ||
  stringIncludes(message, "invalid") ||
  stringIncludes(message, "not valid") ||
  stringIncludes(message, "500") ||
  stringIncludes(message, "404") ||
  stringIncludes(message, "400")
 );
};

export {
 getAxiosTargetPath,
 getAge,
 getAgeMonth,
 getWorkDuration,
 options,
 capitalizeEachWord,
 truncateString,
 convertSeconds,
 countActiveEmployee,
 countStatusEmployee,
 stringIncludes,
 toLowerCaseSafe,
 calculateLate,
 attendanceText,
 getTimeAgo,
 getOutletColumn,
 getNumberColumn,
 getIdNameColumns,
 getAttIdNameColumns,
 getColumn,
 getDateResignColumn,
 isValidObject,
 getPercentDiff,
 round,
 isErrorMessage,
};
