import React, { useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import ReusableTable from "./ReusableTable";
import {
 getAttIdNameColumns,
 getColumn,
 getNumberColumn,
} from "../utils/utils";

const AttendanceSummaryModalLembur = ({
 Title = "Title",
 visible = false,
 onClose = () => {},
 employees = [],
}) => {
 const columns = useMemo(
  () => [
   ...getNumberColumn(),
   ...getAttIdNameColumns(),
   getColumn("Outlet", "outlet", "left"),
   getColumn("Date", "date", "right", false),
   getColumn("Clock In", "clockIn", "left"),
   getColumn("Clock Out", "clockOut", "left"),
   getColumn("Late In Minutes", "lateInMinutes", "left"),
   getColumn("Status", "status"),
   getColumn("Overtime Hours", "overtimeHours"),
   getColumn("Overtime Type", "overtimeType"),
   getColumn("Description", "description", "left", false),
  ],
  []
 );
 if (!employees?.length) return null;

 return (
  <Modal
   show={visible}
   onHide={onClose}
   size="xl"
   dialogClassName="modal-responsive"
   style={{ left: "20px" }}
   className="d-flex justify-content-center"
  >
   <Modal.Header closeButton>
    <Modal.Title>{Title}</Modal.Title>
   </Modal.Header>
   <Modal.Body>
    {employees?.length > 0 ? (
     <ReusableTable columns={columns} data={employees} />
    ) : (
     <p className="text-center">No employee data available.</p>
    )}

    <Button variant="secondary" onClick={onClose}>
     Close
    </Button>
   </Modal.Body>
  </Modal>
 );
};

export default AttendanceSummaryModalLembur;
