import { React, useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { isErrorMessage, stringIncludes } from "../utils/utils";

const Message = ({ variant = "", children = "", useTimer = false }) => {
 const [show, setShow] = useState(true);

 useEffect(() => {
  if (useTimer) {
   const timer = setTimeout(() => {
    setShow(false);
   }, 5000); // Alert will dismiss after 5 seconds

   return () => clearTimeout(timer); // Cleanup timer on unmount
  }
 }, [useTimer]);

 const getVariant = () => {
  if (isErrorMessage(children)) return "danger";
  if (stringIncludes(children, "complete")) return "success";
  if (stringIncludes(children, "success")) return "success";
  return "info";
 };

 const getIcon = () => {
  if (stringIncludes(getVariant(), "danger")) {
   return (
    <i
     className="fas fa-times-circle"
     style={{
      fontSize: "1em",
      color: "#dc3545",
      marginRight: "10px",
     }}
    />
   );
  }
  if (stringIncludes(getVariant(), "success")) {
   return (
    <i
     className="fas fa-check-circle"
     style={{
      fontSize: "1em",
      color: "#28a745",
      marginRight: "10px",
     }}
    />
   );
  } else {
   return (
    <i
     className="fas fa-info-circle"
     style={{
      fontSize: "1em",
      color: "#74C0FC",
      marginRight: "10px",
     }}
    />
   );
  }
 };

 if (!children) return "";

 return show ? (
  <Alert
   variant={variant || getVariant()}
   onClose={() => setShow(false)}
   dismissible
  >
   {getIcon()}
   {children}
  </Alert>
 ) : null;
};

export default Message;
