import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const FormContainer = ({ children }) => {
 return (
  <Container>
   <Row
    className="justify-content-sm-center"
    style={{
     backgroundColor: "rgba(225, 225, 225, 0.87)",
     borderRadius: 8,
     color: "#000000",
     backdropFilter: "blur(2px)" /* Efek blur */,
    }}
   >
    <Col xs={12} sm={8} md={7} lg={7} xl={7}>
     {children}
    </Col>
   </Row>
  </Container>
 );
};

export default FormContainer;
