import React from "react";
import "./css/index.css";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// eslint-disable-next-line
import "./css/coreui.min.css";
import "./css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";

// Membuat instance QueryClient
const queryClient = new QueryClient();

// Render your application
const container = document.getElementById("root");
const root = createRoot(container); // Create a root
root.render(
 <Provider store={store}>
  <QueryClientProvider client={queryClient}>
   <App />
  </QueryClientProvider>
 </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
