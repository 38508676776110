import { IconButton, Stack } from "rsuite";
import React, { useState, useEffect, useMemo } from "react";
import {
 MdKeyboardArrowLeft,
 MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import logoImage from "../Assets/Image/LogoFullColour.png";
import logoImageNoOutline from "../Assets/Image/LogoFullColourNoOutline.png";
import { Image, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { logout, updateUserLastSeen } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
//import iconAttendanceMachine from "../Assets/icon/attendance_machine.png";
//import iconUsers from "../Assets/icon/users.png";
import { updateLocalStorageSettings } from "../utils/localStorage";
import { useQuery } from "@tanstack/react-query";
import {
 CSidebar,
 CSidebarHeader,
 CSidebarBrand,
 CSidebarNav,
 CNavItem,
 CSidebarFooter,
} from "@coreui/react";
import { capitalizeEachWord } from "../utils/utils";
import SidebarItem from "./SidebarItem";

const Sidebar = ({ match, history }) => {
 const dispatch = useDispatch();
 const logoutHandler = (e) => {
  if (e) {
   e.preventDefault(); // Mencegah halaman refresh
  }

  dispatch(logout());
 };
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo, loading: userLoginLoading } = userLogin;
 const token = userInfo?.token;

 const settingsDetails = useSelector((state) => state.settingsDetails);
 const { settings } = settingsDetails;

 const [expand, setExpand] = useState(settings?.sideNav?.expand ?? false);
 // eslint-disable-next-line
 const [isMobile, setIsMobile] = useState(false); // Track if the user is on mobile

 const { data: dataUpdateUserLastSeen, loading: loadingUpdateUserLastSeen } =
  useQuery({
   queryKey: ["updateUserLastSeen", {}], // Query key as an array
   queryFn: updateUserLastSeen, // Function to fetch data
   enabled: token ? true : false, // Only fetch if token is available
   staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
   refetchInterval: 1000 * 5,
   retry: false, // Disable retries on failure
   refetchOnError: false, // Disable automatic refetch when there's an error
  });

 const userLastSeen = useMemo(() => {
  return dataUpdateUserLastSeen || dataUpdateUserLastSeen?.message || [];
 }, [dataUpdateUserLastSeen]);

 const loading = useMemo(() => {
  return userLoginLoading || loadingUpdateUserLastSeen;
 }, [userLoginLoading, loadingUpdateUserLastSeen]);

 //useEffect(() => {
 // const handleResize = () => {
 //  setIsMobile(window.innerWidth < 768); // If screen is smaller than 768px, it's mobile
 // };
 //
 // window.addEventListener("resize", handleResize);
 // handleResize(); // Call it once to set the initial state
 // return () => window.removeEventListener("resize", handleResize);
 //}, [expand]);

 useEffect(() => {
  if (expand !== undefined) {
   updateLocalStorageSettings(`sideNav`, `expand`, expand);
  }
  // eslint-disable-next-line
 }, [expand, userInfo, loading, userLastSeen]);

 if (!userInfo) {
  return <></>;
 }

 const expandToggleHandler = () => {
  setExpand(!expand);
 };
 //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
 return (
  <div style={{ width: userInfo && expand ? "200px" : 0 }}>
   <CSidebar
    className="navbar-expand-sm"
    colorScheme="dark"
    style={{
     left: 0,
     top: 0,
     width: expand ? 200 : 70,
     zIndex: 9999999999,
     height: "100%",
     backgroundColor: "#212529", // dark color
    }}
    position="fixed"
    visible={true}
    size="sm"
   >
    <CSidebarHeader>
     <CSidebarBrand>
      <Logo expand={expand} darkMode={true} />
     </CSidebarBrand>
    </CSidebarHeader>
    <CSidebarNav>
     <LinkContainer to="/dashboard">
      <CNavItem>
       <SidebarItem
        faIconClassName="fas fa-home"
        label="Dashboard"
        expand={expand}
       />
      </CNavItem>
     </LinkContainer>
     <LinkContainer to="/employeeList">
      <CNavItem>
       <SidebarItem
        faIconClassName="fas fa-user"
        label="Employee List"
        expand={expand}
       />
      </CNavItem>
     </LinkContainer>
     <LinkContainer to="/attendance">
      <CNavItem>
       <SidebarItem
        faIconClassName="fas fa-clock"
        label="Attendance"
        expand={expand}
       />
      </CNavItem>
     </LinkContainer>
     {userInfo?.isAdmin && (
      <LinkContainer to="/userList">
       <CNavItem>
        <SidebarItem
         faIconClassName="fas fa-users"
         label="User List"
         expand={expand}
        />
       </CNavItem>
      </LinkContainer>
     )}

     {userInfo?.isAdmin && (
      <LinkContainer to="/settings">
       <CNavItem>
        <SidebarItem
         faIconClassName="fas fa-cog"
         label="Settings"
         expand={expand}
        />
       </CNavItem>
      </LinkContainer>
     )}
     <NavToggle expand={expand} onChange={expandToggleHandler} />
    </CSidebarNav>
    <CSidebarFooter style={{ marginLeft: "-18px", marginRight: "-15px" }}>
     <CSidebarNav>
      <LinkContainer to="/profile">
       <CNavItem>
        <SidebarItem
         faIconClassName="fas fa-user-circle"
         label={capitalizeEachWord(userInfo?.name)}
         expand={expand}
         imageSrc={
          userInfo?.profilePicture ? `../${userInfo?.profilePicture}` : ""
         }
        />
       </CNavItem>
      </LinkContainer>
      <LinkContainer to="/login">
       {expand ? (
        <CNavItem onClick={(e) => logoutHandler(e)}>
         <SidebarItem
          faIconClassName="fas fa-sign-out-alt"
          label="Sign out"
          expand={expand}
          color="#dc3545"
         />
        </CNavItem>
       ) : (
        <></>
       )}
      </LinkContainer>
     </CSidebarNav>
    </CSidebarFooter>
   </CSidebar>
  </div>
 );
};

const NavToggle = ({ expand, onChange }) => {
 return (
  <Stack
   className="nav-toggle"
   justifyContent={expand ? "flex-end" : "center"}
   style={{ marginTop: "5px" }}
  >
   <IconButton
    onClick={onChange}
    appearance="subtle"
    size="lg"
    icon={expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />}
    style={{ color: "#212529", backgroundColor: "#ffffff" }}
   />
  </Stack>
 );
};

const Logo = ({ children, expand, darkMode = false }) => {
 return (
  <>
   <Row>
    <Col md="auto" className="d-flex justify-content-center">
     <Image
      src={darkMode ? logoImage : logoImageNoOutline}
      alt="Logo"
      style={
       expand
        ? {
           width: "125px",
           height: "80px",
           justifyContent: "center",
          }
        : {
           width: "40px",
           height: "25px",
           justifyContent: "center",
          }
      }
     />
    </Col>
   </Row>
  </>
 );
};

export default Sidebar;
