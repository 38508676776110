import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CWidgetStatsA } from "@coreui/react";
import { getPercentDiff, round } from "../../utils/utils";
import Chart from "./Chart";
import { stringIncludes, convertSeconds } from "../../utils/utils";

const WidgetStats = ({
 allData = [],
 widgetColor = "dark",
 faIcon = "fas fa-user",
 CIcon = "cil-user",
 onClick = () => {},
 title,
 chartType = "bar",
 dataKey = ["attendanceSummary", "totalLateInDaysMoreThan5Min3x"],
 style = {},
 valueInPercent = false,
}) => {
 const { percentDiff, latestData } = useMemo(() => {
  if (!allData) return undefined;
  const latestArray = allData?.slice(-1); // Mengambil elemen terakhir, return [latestData]
  const latestData = latestArray?.map((record) => {
   // Menavigasi objek berdasarkan jalur yang diberikan
   return dataKey?.reduce((obj, key) => (obj ? obj[key] : undefined), record);
  });

  const dataArray = allData?.map((record) => {
   // Menavigasi objek berdasarkan jalur yang diberikan dalam lateDataKey
   return dataKey?.reduce((obj, key) => (obj ? obj[key] : undefined), record);
  });

  const percentDiff = getPercentDiff(dataArray);

  return { percentDiff, latestData };
 }, [allData, dataKey]);

 const workingHour = dataKey?.map((item) => {
  return stringIncludes(item, "totalWorkingDurationInSeconds");
 });
 // Cek apakah ada nilai true dalam array workingHour
 const hasTrueValueWorkingHour = workingHour?.some((value) => value === true);

 return (
  <CWidgetStatsA
   color={style?.color ? style?.color : widgetColor}
   style={style}
   icon={
    faIcon ? (
     <i className={faIcon} style={{ fontSize: "1.25em" }}></i>
    ) : (
     CIcon ?? ""
    )
   }
   value={
    <>
     {valueInPercent
      ? round(latestData, 2)
      : hasTrueValueWorkingHour
      ? convertSeconds(latestData)?.hours
      : latestData}
     {valueInPercent && "%"}{" "}
     <span className="fs-6 fw-normal">
      ({`${percentDiff}%`}
      {percentDiff && percentDiff >= 0.01 && percentDiff <= 10000 ? (
       <i
        className="fas fa-arrow-up text-success"
        style={{ marginLeft: "5px" }}
       ></i>
      ) : percentDiff && percentDiff <= 0.01 && percentDiff >= -10000 ? (
       <i
        className="fas fa-arrow-down text-danger"
        style={{ marginLeft: "5px" }}
       ></i>
      ) : (
       ""
      )}
      )
     </span>
    </>
   }
   title={
    <span style={{ cursor: "pointer" }} onClick={onClick}>
     {title}
    </span>
   }
   chart={
    <Chart
     chartType={chartType}
     allData={allData}
     title={title}
     dataKey={dataKey}
    />
   }
  />
 );
};

WidgetStats.propTypes = {
 title: PropTypes.string.isRequired,
 allData: PropTypes.array.isRequired,
 dataKey: PropTypes.array.isRequired,
 widgetColor: PropTypes.string,
 faIcon: PropTypes.string,
 CIcon: PropTypes.string,
 onClick: PropTypes.func,
 chartType: PropTypes.string,
 style: PropTypes.object,
};

export default WidgetStats;
