import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InstagramLink from "./InstagramLink.jsx";
import FacebookLink from "./FacebookLink.jsx";
import LinktreeLink from "./LinktreeLink.jsx";

const Footer = () => {
 return (
  <Container className="footer mt-4" style={{ backgroundColor: "white" }}>
   <Container className="d-flex justify-content-center">
    <Row>
     <Col>
      <InstagramLink />
      <LinktreeLink />
      <FacebookLink />
     </Col>
    </Row>
   </Container>
   <Container className="d-flex justify-content-center">
    <Row>
     <Col>Version 1.1.0 &copy;Sinar Utama Mie Ayam Setiap Hari</Col>
    </Row>
   </Container>
  </Container>
 );
};

export default Footer;
