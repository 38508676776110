import React from "react";
import { Row, Col } from "react-bootstrap";

const SidebarItem = ({
 faIconClassName = "",
 label = "",
 expand = false,
 imageSrc = "",
 fontSize = "1.3em",
 width = "20px",
 height = "20px",
 color = "",
}) => {
 return (
  <Row
   className={`d-flex justify-content-${
    expand ? "start" : "center"
   } align-items-center g-4`}
  >
   <Col md={1} xs={1} sm={1}>
    {imageSrc && !faIconClassName ? (
     <img
      src={imageSrc}
      alt={label}
      style={{
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       width: width,
       height: height,
      }}
      loading="lazy"
     />
    ) : (
     <i
      className={faIconClassName}
      style={{
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       fontSize: fontSize,
       color: color || "inherit",
      }}
     />
    )}
   </Col>
   {expand && (
    <Col
     md={1}
     xs={1}
     sm={1}
     style={{
      opacity: expand ? 1 : 0,
      transition: "opacity 0.3s ease-in-out",
      color: color || "inherit",
     }}
    >
     {label}
    </Col>
   )}
  </Row>
 );
};

export default SidebarItem;
