import React from "react";
import { Button, Spinner } from "react-bootstrap";

const IButton = ({
 iconClassName = "fas fa-upload", // use Font Awesome icons
 progress = 0,
 onClick = () => {},
 disabled = false,
 variant = "danger",
 label = "Upload",
 loadingLabel = "Uploading...",
 loading = false,
 size = "sm",
 marginRight = "5px",
}) => {
 return (
  <Button
   variant={variant}
   onClick={onClick}
   disabled={disabled || (progress > 0 && progress < 100) || loading}
  >
   {(progress > 0 && progress < 100) || loading ? (
    <>
     <Spinner
      as="span"
      animation="border"
      size={size}
      role="status"
      aria-hidden="true"
      style={{ marginRight: "5px" }}
     />
     {`${loadingLabel} ${progress}%`}
    </>
   ) : (
    <>
     <i
      className={iconClassName}
      aria-hidden="true"
      style={{ marginRight: marginRight }}
     />
     {label}
    </>
   )}
  </Button>
 );
};

export default IButton;
